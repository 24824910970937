<template>
  <b-card>
    <b-row>
      <b-button
        class="btn-filter"
        variant="adn"
        @click="
          () => {
            showFilter = !showFilter;
            ResetSelection();
          }
        "
      >
        {{ !showFilter ? this.$t('showFilter') : this.$t('hideFilter') }}
        <feather-icon icon="FilterIcon" class="danger" />
      </b-button>
    </b-row>

    <b-row v-if="showFilter" class="filter-section">
      <b-col lg="3" md="4" sm="6" xs="12">
        <g-field
          id="transactionNumber"
          v-silent-search
          :value.sync="filter.transactionNumber"
          label-text="transactionNumber"
          name="transactionNumber"
          @keydown.enter="() => refreshItems()"
        />
      </b-col>

      <b-col lg="3" md="4" sm="6" xs="12">
        <label class="font-small-3" for="example-datepicker">
          {{ $t('fromDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.transactionDateFrom"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="transactionDateFrom"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col lg="3" md="4" sm="6" xs="12">
        <label class="font-small-3" for="example-datepicker">
          {{ $t('toDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.transactionDateTo"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="transactionDateTo"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col lg="3" md="4" sm="6" xs="12">
        <!-- <g-field
          :value.sync="filter.studentId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="student"
          field="select"
          :options="students"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        /> -->
        <student-autocomplete
          ref="autoComlete"
          name="student"
          :value.sync="filter.studentId"
          url="students/getStudentsTaxLookup"
          @change:action="
            (val) => {
              this.students.push(val), refreshItems();
            }
          "
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col lg="3" md="4" sm="6" xs="12">
        <g-field
          :value.sync="filter.stageId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="stage"
          field="select"
          :options="stages"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col lg="3" md="4" sm="6" xs="12">
        <g-field
          :value.sync="filter.rowId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="row"
          field="select"
          :options="rows"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col lg="3" md="4" sm="6" xs="12">
        <g-field
          :value.sync="filter.classroomId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="class"
          field="select"
          :options="classrooms"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col lg="3" md="4" sm="6" xs="12">
        <g-field
          :value.sync="filter.paymentWay"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="paymentWay"
          field="select"
          :options="paymentWays"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <!-- group type selection -->
      <b-col class="gb-package mt-1" lg="3" md="4" sm="6" xs="12">
        <label> {{ $t('accountsPostSituation') }} </label>
        <b-button-group>
          <b-button
            :class="['gb-btn-child', { btnActive: postGroupName === 'all' }]"
            variant="flat-warning"
            @click="
              () => {
                postGroupName = 'all';
                refreshItems();
              }
            "
          >
            {{ $t('all') }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: postGroupName === 'posted' }]"
            variant="flat-warning"
            @click="
              () => {
                postGroupName = 'posted';
                refreshItems();
              }
            "
          >
            {{ $t('posted') }}
          </b-button>

          <b-button
            :class="[
              'gb-btn-child',
              { btnActive: postGroupName === 'unPosted' },
            ]"
            variant="flat-warning"
            @click="
              () => {
                postGroupName = 'unPosted';
                refreshItems();
              }
            "
          >
            {{ $t('unPosted') }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <div>
      <div class="row justify-content-end mb-2 mt-2">
        <div
          class="d-flex align-items-center justify-content-end mb-md-0 col-md-4 col-12"
        >
          <b-button
            v-if="this.$route.name === 'collection-transactions'"
            v-permission="'addCollectionTransactions'"
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                if (this.$route.name === 'collection-transactions') {
                  this.$router.push({
                    name: 'collection-transactions-new',
                    params: { type: 'collection' },
                  });
                } else {
                  this.$router.push({
                    name: 'payment-transactions-new',
                    params: { type: 'payment' },
                  });
                }
              }
            "
          >
            {{ $t('new') }}
          </b-button>
          <b-button
            v-if="this.$route.name === 'payment-transactions'"
            v-permission="'addPaymentTransactions'"
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                if (this.$route.name === 'collection-transactions') {
                  this.$router.push({
                    name: 'collection-transactions-new',
                    params: { type: 'collection' },
                  });
                } else {
                  this.$router.push({
                    name: 'payment-transactions-new',
                    params: { type: 'payment' },
                  });
                }
              }
            "
          >
            {{ $t('new') }}
          </b-button>
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 btn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px; width: auto"
            />
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 btn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px; width: auto"
            />
          </b-button>
        </div>
      </div>
    </div>

    <g-table
      ref="transaction-table"
      :items="itemsProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      foot-clone
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template #isReviewed="{ item }">
        <span>
          <b-form-group>
            <b-form-checkbox
              v-model="item.isReviewed"
              class="mr-0 mt-50"
              inline
              @change="
                (v) => {
                  isReviewed(item);
                }
              "
            />
          </b-form-group>
        </span>
      </template>
      <template #actions="{ item }" v-if="hideActions === false">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="print(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>

          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editCollectionTransactions'"
            data-action-type="edit"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="
              () => {
                edit(item);
              }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>

          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteCollectionTransactions'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="
              (v) => {
                remove(item);
              }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>

          <b-button
            v-b-tooltip.hover.top="$t('sendMail')"
            v-permission="'sendCollectionTransactionsEmail'"
            data-action-type="send"
            variant="flat-warning"
            class="btn-icon"
            size="sm"
            @click="
              () => {
                sendMail(item);
              }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-send-icon`"
              icon="SendIcon"
            />
          </b-button>

          <b-button
            v-if="item.studentMobile"
            v-b-tooltip.hover.top="$t('whatsAppContact')"
            v-permission="'sendCollectionTransactionsWhatsapp'"
            data-action-type="contact"
            variant="whatsApp"
            class="btn-icon p-25"
            size="sm"
            @click="sendWhatsAppMessage(item)"
          >
            <img
              src="@/assets/images/icons/whatsapp.png"
              width="15"
              alt="whatsappIcon"
            >
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from '@/pages/Shared/Table.vue';
import { paymentWays } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';

export default {
  components: {
    GTable,
    StudentAutocomplete,
  },

  mixins: [reportMixin],

  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      filter: {
        voucherType: null,
        transactionNumber: null,
        transactionDateFrom: null,
        transactionDateTo: null,
        studentId: null,
        stageId: null,
        rowId: null,
        classroomId: null,
        paymentWay: null,
        isPosted: null,
      },
      isTableBusy: false,
      totalRows: 0,
      items: [],
      suppliers: [],
      paymentWays: paymentWays,
      pathName: this.$route.name,
      totalVals: 0,
      itemsArray: [],
      hideActions: false,
      showFilter: false,
      postGroupName: 'all',
      fiscalYearStart: '',
      fiscalYearEnd: '',
      encryptedId: '',
      students: [],
      stages: [],
      rows: [],
      classrooms: [],
    };
  },

  computed: {
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('code'),
          sortable: false,
        },
        {
          key: 'transactionDate',
          field: 'voucherDate',
          label: this.$t('date'),
          sortable: false,
        },
        {
          key: 'studentName',
          field: 'studentName',
          label: this.$t('student'),
          sortable: false,
        },
        {
          key: 'paymentMethodName',
          field: 'paymentMethodName',
          label: this.$t('Payment Method'),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.paymentMethod === 'cash')
              return `<span class="p-50 main-space badge bg-gradient-success bg-darken-1">${this.$t(
                'cash'
              )}</span>`;
            if (item.paymentMethod === 'other')
              return `<span class="p-50 main-space badge bg-gradient-primary bg-darken-1">${this.$t(
                'other'
              )}</span>`;
          },
        },
        {
          key: 'isElectronicPayment',
          field: 'isElectronicPayment',
          label: this.$t('HowToCreated'),
          sortable: false,
          formatter: (key, value, elec) => {
            if (elec.isElectronicPayment )
              return `<span class="p-50 main-space badge bg-gradient-success bg-darken-1">${this.$t(
                'electorinc'
              )}</span>`;
            if (!elec.isElectronicPayment)
              return `<span class="p-50 main-space badge bg-gradient-primary bg-darken-1">${this.$t(
                'manual'
              )}</span>`;
          },
        },
        {
          key: 'sourceInvoiceId',
          field: 'sourceInvoiceId',
          label: this.$t('status'),
          sortable: false,
          formatter: (key, value, tanx) => {
            if(tanx.sourceInvoiceId) return `<span class="p-50 main-space badge bg-gradient-danger bg-darken-1">${this.$t('relatedToInvoiceNo', {invoiceNo: tanx.sourceInvoiceCode })}</span>`;
          },
        },
        {
          key: 'voucherValue',
          field: 'voucherValue',
          label: this.$t('value'),
          sortable: true,
          type: 'number',
          footer: () => this.fraction(this.totalVals),
        },
        {
          key: 'updatedStatus',
          field: 'updatedStatus',
          label: this.$t('isPosted'),
          sortable: false,
          formatter: (key, value, tanx) => {
            if (tanx.isPosted)
              return `<span class="p-50 main-space badge bg-gradient-secondary bg-darken-1">${this.$t(
                'posted'
              )}</span>`;
            return `<span class="p-50 main-space badge bg-gradient-danger bg-darken-1">${this.$t(
              'unPosted'
            )}</span>`;
          },
        },
        {
          key: 'notes',
          field: 'notes',
          label: this.$t('notes'),
          sortable: false,
        },
        { key: 'actions' },
      ];
    },
  },
  watch: {
    '$route.name': 'refreshItems',
    'filter.transactionDateFrom'(newVal) {
      if (newVal > this.filter.transactionDateTo) {
        this.doneAlert({
          title: this.$t('startDateShouldBelessThanEndDate'),
          type: 'error',
        });
        return false;
      }
      return true;
    },
    'filter.transactionDateTo'(newVal) {
      if (this.filter.transactionDateFrom > newVal) {
        this.doneAlert({
          title: this.$t('startDateShouldBelessThanEndDate'),
          type: 'error',
        });
        return false;
      }
      return true;
    },
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.filter.transactionDateFrom = this.getDate(this.fiscalYearStart);
    this.filter.transactionDateTo = this.getDate(this.fiscalYearEnd);
    this.loadData();
  },

  methods: {
    itemsProvider(ctx, callback) {
      const { currentPage, perPage, sortBy, sortDesc } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      var params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;
      this.setFilertPost();

      if (this.$route.name === 'collection-transactions') {
        this.transactionType = 'collectionVouchers';
        this.filter.voucherType = 1;
      } else {
        this.transactionType = 'paymentVouchers';
        this.filter.voucherType = 2;
      }

      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;

      this.get({ url: `${this.transactionType}${params}` })
        .then((data) => {
          this.isTableBusy = false;
          this.totalVals = 0;
          this.totalRows = data.totalRecords;
          data.pageData.forEach((item) => {
            this.totalVals += item.voucherValue;
            item.transactionDate = this.getDate(item.transactionDate);
          });
          callback(data.pageData);

          // then set items for excel and pdf
          this.itemsArray = data.pageData;
          this.itemsArray.forEach((element) => {
            element.updatedStatus = element.isPosted
              ? this.$t('posted')
              : this.$t('unPosted');
            element.paymentMethodName =
              element.paymentMethod === 'cash'
                ? this.$t('cash')
                : this.$t('bankAccount');
          });
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },

    loadData() {
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data;
      });
      this.get({ url: 'Rows' }).then((data) => {
        this.rows = data;
      });
      this.get({ url: 'Classrooms' }).then((data) => {
        this.classrooms = data;
      });
    },

    ResetSelection() {
      if (!this.showFilter) {
        this.filter.transactionDateFrom = this.fiscalYearStart;
        this.filter.transactionDateTo = this.fiscalYearEnd;
        this.filter.transactionNumber = null;
        this.filter.studentId = null;
        this.filter.stageId = null;
        this.filter.rowId = null;
        this.filter.classroomId = null;
        this.filter.paymentWay = null;
        this.postGroupName = 'all';
        this.refreshItems();
      }
    },

    setFilertPost() {
      switch (this.postGroupName) {
        case 'posted':
          this.filter.isPosted = true;
          break;

        case 'unPosted':
          this.filter.isPosted = false;
          break;

        default:
          this.filter.isPosted = null;
          break;
      }
    },

    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas) {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF('p', 'mm');
        var position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },

    printDocument() {
      this.hideActions = true;
      setTimeout(() => {
        this.pdfExport(this.$route.name);
        this.hideActions = false;
      }, 500);
    },

    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas) {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF('p', 'mm');
        var position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    refreshItems() {
      this.$refs['transaction-table'].refreshTable();
    },

    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          if (this.$route.name === 'collection-transactions') {
            this.delete({
              url: 'CollectionVouchers',
              id: item.id,
            }).then(() => {
              this.doneAlert({ text: this.$t('deletedSuccessfully') });
              this.refreshItems();
            });
          } else {
            this.delete({
              url: 'PaymentVouchers',
              id: item.id,
            }).then(() => {
              this.doneAlert({ text: this.$t('deletedSuccessfully') });
              this.refreshItems();
            });
          }
        }
      );
    },

    edit(item) {
      if (this.$route.name === 'collection-transactions') {
        this.$router.push({
          name: 'collection-transactions-edit',
          params: { id: item.id, type: 'collection' },
        });
      } else {
        this.$router.push({
          name: 'payment-transactions-edit',
          params: { id: item.id },
        });
      }
    },

    sendMail(voucher) {
      if (!voucher.guardianId) {
        this.doneAlert({
          text: this.$t('studentNotRelatedToGuardain'),
          type: 'warning',
        });
        return;
      }
      this.create({ url: `CollectionVouchers/send-mail/${voucher.id}` }).then(
        () => {
          this.doneAlert({ text: this.$t('sendSuccessfully') });
        }
      );
    },

    print(item) {
      const reportName = this.isRight ? 'FinancialTransaction-ar' : 'FinancialTransaction-en';
      const printedItem = {
        id: item.id,
        voucherType: item.voucherType === 'collectionDebenture' ? 0 : 1,
      };
      this.printReport(reportName, printedItem);
    },

    getMessage(item, reportUrl) {
      const to = `السيد ولي أمر الطالب / ${item.studentName}`
      const content = `إليك التقرير الخاص بسند القبض رقم ${item.code} المقام بتاريخ ${item.transactionDate}`
      const greating = 'نتمني لك يوم سعيد'
      const instructions = 'رابط التحميل المباشر :'
      return `${to}%0A${content}%0A${greating}%0A%0A${instructions}%0A${reportUrl}`
    },

    sendWhatsAppMessage(item) {
      // prepare reportUrl
      if (this.baseReportURL.slice(-1) === '?') {
        this.baseReportURL = this.baseReportURL.slice(0, -1);
      }

      // encrypt id then send to guardian
      this.get({ url: `CollectionVouchers/encrypt/${item.id}` }).then((data) => {
        this.encryptedId = data;
        const reportUrl = `${this.baseReportURL}/shareCollectionVoucher/${this.encryptedId}`
        const message = this.getMessage(item, reportUrl);
        // window.open(`http://wa.me/${item.studentMobile}?text=${reportUrl}` , '_blank');
        window.open(`http://web.whatsapp.com/send?phone='${item.studentMobile}&icebreaker=${message}&&source_url=${reportUrl}` , '_blank');
      });
    },
  },
};
</script>

<style>
.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0 5px;
  border: 2px solid #ad8884;
}

.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 3px;
}

.gb-package label {
  font-size: 14px;
  margin-bottom: 10px;
}
b-button-group {
  border-radius: 6px;
}

.gb-btn-child {
  border: 1px solid gainsboro;
}

.btnActive {
  background-color: rgba(255, 174, 92, 0.2);
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
